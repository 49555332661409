.hero {
  height: 100vh;
  display: flex;
  font-size: var(--font-size-base);
}

.hero__panel {
  --anim-delay: 0s;

  height: 100%;
  width: 50%;
  position: relative;

  img,
  video {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;

    &:not(:first-child) {
      opacity: 0;
      z-index: -1;
    }
  }
}

.hero__overlay {
  @include overlay;

  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    appearance: none;
    border: none;
    padding: 0;
    font-size: inherit;
    background: no-repeat;
    cursor: pointer;
    margin-bottom: 7vh;
  }

  svg {
    width: 1.1em;
    height: 1.1em;
    fill: $body-color;
    margin: 0.33em;
  }
}

.hero__overlay__logo {
  margin-top: auto;
  margin-bottom: 7vh;
  flex: 0 1 auto;
  position: relative;
  height: 70vh;

  img { /* stylelint-disable-line no-descending-specificity */
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .mask {
    @include overlay;

    background-image: url("../media/logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    mask-image: url("../media/logo-mask.png");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background-blend-mode: multiply;
  }
}

.playbtn {
  &:not(.playing) .playbtn--on-pause {
    display: none;
  }

  &.playing .playbtn--on-play {
    display: none;
  }
}
