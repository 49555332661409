@font-face {
  font-family: "GT America Mono";
  src: url("fonts/GT-America-Mono-Regular.woff") format("woff");
}

@font-face {
  font-family: "Nuber Next";
  src: url("fonts/NuberNext-Regular.woff") format("woff");
}

:where(html) {
  line-height: 1.2;
}

:root {
  --scale: 0.5;
  --spacer: calc(var(--scale) * 1rem);
  --font-size-base: calc(var(--scale) * 1.875rem);
  --font-size-sm: calc(var(--scale) * 1.5rem);
  --font-size-lg: calc(var(--scale) * 3.125rem);
  --font-size-xl: calc(var(--scale) * 2.25rem);
  --font-size-xxl: calc(var(--scale) * 4.375rem);
  --spacing-y: calc(var(--spacer) * 12);
}

@media (min-width: $screen-xl) {
  :root {
    --scale: 0.66;
  }
}

@media (min-width: $screen-xxl) {
  :root {
    --scale: 1;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
video {
  max-width: 100%;
  display: block;
}

html {
  scroll-behavior: smooth;
}

body {
  color: $body-color;
  font-family: $ff-base;
  background: $bg-color;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' height='48' width='48'%3E%3Cpath fill='%23fff' d='M12.89,61.44l6.77-17.19L2.47,51,0,48.46l21.67-8.41,2.38,2.37L15.54,64Z'/%3E%3Cpath fill='%23fff' d='M33,41.33l6.76-17.19L22.58,30.9,20,28.34l21.67-8.41,2.38,2.38L35.66,44Z'/%3E%3Cpath fill='%23fff' d='M53,21.3,59.79,4.11,42.61,11,40.05,8.41,61.71,0,64,2.29,55.59,24Z'/%3E%3C/svg%3E") 46 2, auto;
}

svg {
  fill: currentColor;
}

a:link,
a:visited {
  color: inherit;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.15em;
}

p a:hover {
  color: $green;
}

a.color-link {
  color: $green;
  text-decoration-line: none;

  &:hover {
    text-decoration-line: underline;
  }
}

p,
h1,
h2 {
  margin-top: 0;
}

p,
ul {
  font-size: var(--font-size-base);
}

.text--sm {
  font-size: var(--font-size-sm);
}

.text--xl {
  font-size: var(--font-size-xl);
}

.text--xxl {
  font-size: var(--font-size-xxl);
  line-height: 1.08;
}

p.text--xxl,
p.text--xl {
  margin-bottom: calc(1.2 * var(--font-size-xl));
}

.text--center {
  text-align: center;
}

.links {
  list-style: none;
  padding: 0;
  display: flex;
  column-gap: calc(var(--spacer) * 2.5);
  row-gap: calc(var(--spacer) * 2.5);
  justify-content: center;
  text-transform: uppercase;
  flex-wrap: wrap;
  margin-top: calc(6 * var(--spacer));

  .link {
    text-decoration-line: none;

    &:hover {
      text-decoration-line: underline;
    }
  }
}

.button {
  background: $body-color;
  color: $bg-color;
  border: 1px solid $body-color;
  appearance: none;
  font-size: inherit;
  padding: 0.3em 1.4em;
  border-radius: 0.25em;
  cursor: pointer;

  &:hover {
    background: $bg-color;
    color: $body-color;
  }
}

.contact-form {
  font-size: var(--font-size-base);

  label {
    display: block;
    text-transform: uppercase;
    margin-bottom: calc(var(--spacer) * 2.5);
  }

  input[type="email"] {
    font-size: inherit;
    background: transparent;
    color: currentColor;
    border: 1px solid transparent;
    border-bottom-color: currentColor;
    width: 10em;
    margin-right: 1em;
    padding: 0.3em;

    @media (min-width: $screen-md) {
      width: 20em;
    }
  }

  ::placeholder {
    opacity: 1;
  }
}
