.video {
  position: relative;
}

.video__overlay {
  @include overlay;

  z-index: 1;
  box-shadow: 7px 7px 5px rgba(0, 0, 0, 75%);

  .video__btn {
    @include overlay;

    border: none;
    color: currentColor;
    appearance: none;
    background: none;
    font-size: var(--font-size-base);
    cursor: pointer;

    svg {
      width: 4.66em;
      filter: drop-shadow(7px 7px 5px rgba(0, 0, 0, 75%));

      @media (max-width: $screen-sm - 1) {
        width: 3.5em;
        margin-top: 5em;
      }
    }
  }

  .playing & {
    display: none;
  }
}

.video__title--container {
  @include overlay;

  background-color: #001a52;
  padding: calc(1.25 * var(--spacer)) calc(1.875 * var(--spacer));
  z-index: 1;

  .playing & {
    background-color: transparent;
  }
}

.video__slide {
  position: relative;

  p {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: calc(1.25 * var(--spacer)) calc(1.875 * var(--spacer));
    margin: 0;
    font-size: var(--font-size-sm);
  }

  &:not(:first-child) {
    @include overlay;

    opacity: 0;
    z-index: -1;
  }
}
