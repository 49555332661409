.card {
  --bg-col: transparent;

  padding: calc(1.25 * var(--spacer)) calc(1.875 * var(--spacer));
  background: var(--bg-col);
  box-shadow: 7px 7px 5px rgba(0, 0, 0, 75%);
  height: 100%;
  font-size: var(--font-size-lg);
}

.card__title {
  text-transform: uppercase;
  font-weight: 400;
  z-index: 2;
  position: relative;
  font-family: $ff-mono;
  font-size: var(--font-size-lg);
}

.card--book {
  border-radius: 0 0.5rem 0.5rem 0;
  aspect-ratio: 2 / 3;
  flex: 0 1 80%;
  position: relative;
  overflow: hidden;

  @media (min-width: $screen-md) {
    flex-basis: 38%;
  }

  @media (min-width: $screen-lg) {
    flex-basis: 6em;
  }

  @media (min-width: $screen-xl) {
    flex-basis: 5em;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 200%;
    aspect-ratio: 4 / 5;
    transform: translate3d(0, 0, 0);
    background-size: 100% auto;
    background-image: url("../media/issues/holo-bg.jpg");

    /*
      Could'n use css var because of Safari bug
      https://stackoverflow.com/questions/66752308/css-background-image-relative-path-var-on-safari-not-loading-image
    */
    mask-image: url("../media/issues/intimacy.png");
    mask-size: 100% auto;
    opacity: 0;
  }
  
  &.reintegration::after {
    mask-image: url("../media/issues/reintegration.png");
    width: 238%;
  }

  &.displacement::after {
    mask-image: url("../media/issues/displacement.png");
    width: 238%;
  }

  &.translation::after {
    mask-image: url("../media/issues/translation.png");
  }

  &.superstitions::after {
    mask-image: url("../media/issues/superstitions.png");
    width: 228%;
  }

  &.diagnosis::after {
    mask-image: url("../media/issues/diagnosis.png");
    width: 228%;
  }

  &:hover::after,
  .tapped::after {
    animation: cardhover 8s linear infinite;
    opacity: 1;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }
}

@supports not (aspect-ratio: 2 / 3) {
  .card--book::before {
    float: left;
    padding-top: 150%;
    content: "";
  }

  .card--book::after {
    height: 166.6666%;
  }
}

@keyframes cardhover {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, -40%, 0);
  }
}
