$bg-color: #004d17;
$body-color: #fff;
$blue: rgb(21, 255, 0);
$green: #0f0;

$ff-base: "Nuber Next", sans-serif;
$ff-mono: "GT America Mono", "Consolas", "Andale Mono WT", "Andale Mono", "Lucida Console", monospace;

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;
