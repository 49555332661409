.container {
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $screen-lg) {
    max-width: 960px;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  @media (min-width: $screen-xl) {
    max-width: 1024px;
  }

  @media (min-width: $screen-xxl) {
    max-width: 1552px;
  }
}

.container--spaced {
  & > * {
    margin-bottom: var(--spacing-y);

    &:first-child {
      margin-top: var(--spacing-y);
    }
  }
}

.row {
  display: flex;
  column-gap: calc(4.5 * var(--spacer));
  row-gap: calc(4.5 * var(--spacer));
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: $screen-lg) {
    column-gap: calc(3 * var(--spacer));
    justify-content: space-between;
  }
}

.indent {
  @media (min-width: $screen-md) {
    padding-right: calc(5 * var(--spacer));
    padding-left: calc(5 * var(--spacer));
  }
}

footer {
  padding-top: calc(3.5 * var(--spacer));
}
