.marquee {
  width: 100%;
  display: block;
  background: $blue;
  color: $body-color;
  text-transform: uppercase;
  font-family: $ff-mono;
  font-size: var(--font-size-base);
  padding: 0.25em;
  text-decoration: none;
  overflow: hidden;
}

.marquee_inner {
  width: fit-content;
  display: flex;
  position: relative;

  span {
    white-space: nowrap;
    transform: translate3d(0, 0, 0);
    animation: marquee 5s linear infinite;
    display: inline-block;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .marquee:hover .marquee_inner span {
    animation-play-state: paused;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
